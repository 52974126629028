import * as Sentry from '@sentry/nextjs'
import { Layout } from '@components/common'
import { Container, Heading2 } from '@components/ui'
import { Grid, Column } from '@components/common'

export default function Custom404() {
  if (process.browser) {
    /**
     * Logging 404's to Sentry For Now So We Can Find Any Pages That Are Getting
     * Redirected From Shopify That Shouldn't Be
     */
    Sentry.captureException('404 Not Found')
  }

  return (
    <Container>
      {/* Using Grid to Add Top/Bottom Padding w/o Creating a New Component */}
      <Grid xsGutter={30}>
        <Column xs={12}>
          <Heading2>NOT FOUND</Heading2>
          <p>Sorry, but the page you were trying to view does not exist.</p>
        </Column>
      </Grid>
    </Container>
  )
}

Custom404.Layout = Layout
